#return {
    margin: auto;
}

#rResults {
    margin: 25px auto;
    width: 60%;
    border-radius: 8px;
    background-color: rgb(111, 167, 219);
}

.table td {
    vertical-align: middle;
}