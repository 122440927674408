#nav {
    background-color: #2196F3;
    min-height: 60px;
    margin-bottom: 20px;
    color:aliceblue;
    
    
}

#nav ul {
    list-style-type: none;
    float: right;
}

#nav ul li {
    display: inline;
    padding: 10px 15px;
    font-size: 1.3em;
    color:#ffffff;
    
}

a, a:hover {
    text-decoration: none;
    color: #282c34;
    color:#ffffff;
}

#nav span {
    position: absolute;
    float: none;
    font-size: 1.5em;
    top: 3.5%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#navtext {
    color:#ffffff;
    font-family: 'Rubik Mono One';
    
    
}